import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { Container, Navbar, Nav, Jumbotron, Row, Col } from "react-bootstrap";
import "./App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngular, faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faChevronLeft, faChevronRight, faDatabase } from "@fortawesome/free-solid-svg-icons";
import { faReact, faJava, faPython, faJs, faHtml5, faCss3Alt } from "@fortawesome/free-brands-svg-icons";


import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import BackGround from './BackGround';


const Home = () => (
  <Jumbotron fluid id="home" className="text-center">
    <Container id='homeContainer'>
      <h1 class="em">Logan Dye</h1>
      <p>Software and Web Developer</p>
      
      <p>Java, Python, JavaScript, ReactJS, AngularJS, HTML5, CSS3, SQL</p>
      <div id="icons">
      <FontAwesomeIcon className='icon1' icon={faJava} />
      <FontAwesomeIcon className='icon2'icon={faPython} />
      <FontAwesomeIcon className='icon3'icon={faJs} />
      <FontAwesomeIcon className='icon4'icon={faReact} />
      <FontAwesomeIcon className='icon5'icon={faAngular} />
      <FontAwesomeIcon className='icon6'icon={faHtml5} />
      <FontAwesomeIcon className='icon7'icon={faCss3Alt} />
      <FontAwesomeIcon className='icon8'icon={faDatabase} />
      </div>
    </Container>
  </Jumbotron>
);

const About = () => (
  <section id="about">
    <h2>About Me</h2>
    <p>
    <span class="em">Welcome!</span> My name is Logan Dye, I am 28 years old and local to Columbus, Ohio. I am currently working as a Data Analyst at Morgan Stanley. 
    I am in the process of completing an Associate of Applied Science (AAS) degree in Computer Science: Software Development at Columbus State Community College. 
    My journey into software development has been shaped by diverse experiences, I have been passionate about computers and programming since I was very young. 
    In my spare time, I enjoy tring to recreate popular websites and coming up with creative software and website ideas. 
    I also study many game development languages and technologies as a hobbiest. 
    </p>
    <p>
    I decided to begin a Computer Science degree in January of 2022. 
    To add to my experience, I also began to look for a Helpdesk position at many companies. 
    I eventually got hired on as a Technical Support Specialist for Morgan Stanley, this role largely involved troubleshooting and soft-skills.
    Not long after, I was offered a position as a Data Analyst for a new internal team. 
    Transitioning from a Tech Support Agent to a Data Analyst, I honed my abilities in data manipulation, analysis, and visualization, utilizing tools such as Python, Microsoft Excel, Tableau, and PowerBi. 
    In this role, I work as part of an international team with many of our members overseas.
    We are a ticket managment team, we aid in developing processes for the internal helpdesks and groups.
    This role change was driven by my dedication to continuous growth and learning, achieved through dedication, self-study, and a proactive approach to professional development. 
    My proficiency extends beyond programming languages to encompass Agile methodologies, soft-skills, and technical expertise. 
    </p>
    <p>
    In my spare time, I like to work-out, play video games (my favorite right now is the Fallout series and Starfield), spend time with my cats and lovely wife, and continue to practice my technical skills.
    </p>
  </section>
);

const Employment = () => (
  <section id="employment">

    <h2 class="title employment">Employment History</h2>
    <a target='_blank' href="https://www.morganstanley.com">
    <img id="msLogo" src="/morgan_stanley_logo.jpg"></img>
    </a>
    <h3 class="company">Morgan Stanley</h3>

    <h3 class="company details">January 2023 - Present</h3>
    <h3 class="company details">Columbus, Ohio, United States</h3>
    <ul>
    <li><h4>Data Analyst</h4>
    <h5>Dec 2023 - Present</h5>
    <p>In my current role as a Data Analyst within the Technology End User Services End to End (E2E) team, I play a critical role in managing incident/request ticket data for the end user community. This involves generating daily, weekly, monthly, and ad hoc reports for senior management, highlighting key insights and trends. I also assess ticket quality and aging, identify repeat callers and status callers, and analyze ticket hopping metrics to drive continuous enhancement of end user satisfaction. My responsibilities extend to analyzing tickets to discover trends and ensure a high-quality service experience for our users. As part of a collaborative team effort, I contributed to the development of a program utilizing Python and the pandas data analysis library to analyze and disseminate formatted reports of older tickets to department leads. This initiative has evolved into my permanent role, where I not only maintain the software but also analyze aged tickets to track patterns and trends. Leveraging advanced Microsoft Excel skills, along with tools such as Service Now, Tableau, and PowerBi, is integral to our analytical processes. My commitment to continuous improvement extends beyond ticket analysis, as I seek to optimize our processes and workflows to better serve our end users. By leveraging a combination of technical expertise and strategic insights, I strive to drive positive outcomes and deliver exceptional value to our organization.</p>
    </li>
    <li>
    <h4>IT Support Specialist</h4>
    <h5>Jan 2023 - Present</h5>
    <p>I am responsible for providing technical assistance to employees of the firm. I have experience troubleshooting and resolving technical issues related to computer systems, software, and hardware. I also work on automation projects for the firm, using Python and other programming languages to streamline reporting and automate routine tasks. My role involves analysis of tickets to discover trends and to assist in providing great quality of service to our users. I worked as part of a team to develop a program using Python and the pandas data analysis library to analyze and send out formatted reports of older tickets to their department leads. This team has now become my permanent role, in addition to maintaining the software, we analyze aged tickets and use methods to track patterns and trends. I have also assisted in the implementation and maintenance of new technology and provided training to employees on how to use it effectively. I work closely with local IT and other departments to ensure the high quality of service of the company's technology infrastructure. I am familiar with multiple operating systems and software and have experience with hardware repair and maintenance. I am a problem solver and a quick learner and I enjoy helping people and finding solutions to difficult technical issues.</p>
    </li>
    </ul>
  </section>
)

const Skills = () => (
  <section id="skills">

    <h2>Skills</h2>
    {/* <p>Click to view subskills</p> */}
    <h3>Web Development:</h3>
    <p>
      <span class="skills">HTML5</span>
      <span class="skills">CSS3</span>
      <span class="skills">JavaScript</span>
      <span class="skills">ReactJS</span>
      <span class="skills">AngularJS</span>
      <span class="skills">Node.JS</span>
      <span class="skills">Bootstrap</span>
      <span class="skills">Tailwind</span>
      <span class="skills">AWS</span>
      <span class="skills">Firebase</span>
    </p>
    <h3>Software Development:</h3>
    <p>
      <span class="skills">Python</span>
      <span class="skills">Java</span>
      <span class="skills">C#</span>
      <span class="skills">Visual Basic</span>
      <span class="skills">.NET framework</span>
    </p>
    <h3>
      Technical:
    </h3>
    <p>
      <span class="skills">Data Analysis</span>
      <span class="skills">Continuous Improvement</span>
      <span class="skills">Testing</span>
      <span class="skills">Windows OS</span>
      <span class="skills">Linux OS</span>
      <span class="skills">Mac OS</span>
      <span class="skills">Hardware Devices</span>
      <span class="skills">MobileIron</span>
      <span class="skills">InTune</span>
      <span class="skills">VOIP</span>
      <span class="skills">Security</span>
      <span class="skills">Troubleshooting</span>
      <span class="skills">Visual Studio</span>


    </p>
    <h3>
      Other:
    </h3>
    <p>
     <span class="skills">Process Improvement</span>
     <span class="skills">Agile Methodologies</span>
      <span class="skills">Communication</span>
      <span class="skills">Determination</span>
      <span class="skills">Reliability</span>

    </p>

  </section>
)


const SamplePrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`slick-arrow ${'custom-prev-arrow'}`}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faChevronLeft} />
    </div>
  );
};

const SampleNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`slick-arrow ${'custom-next-arrow'}`} 
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faChevronRight} />
    </div>
  );
};



const ProjectCard = ({ project, isFirst }) => (
  <div className={`project-card ${isFirst ? 'first-project' : ''}`}>
    
    <div className="project-content">
      <h2>{project.title}</h2>
      <h3>{project.name}</h3>
      <p>{project.description}</p>
      <a target='_blank' href={project.githubLink}>{project.githubLink}</a>
    </div>
    <div className="project-image">
      <img src={project.imageSrc} alt={project.title} />
    </div>
  </div>
);

const Projects = () => {
  const projects = [

    {
      title:'Projects',
      id: 1,
      name: "StudyHub - Computer Science Resource Site",
      description: "Website containing computer science articles and links to study materials. Made with JQuery, CSS & Bootstrap, and HTML.",
      githubLink: "https://studylinkhub.netlify.app/",

      imageSrc: "/studyhub.png",
    },
    {
      title:'Projects',
      id: 2,
      name: "AngularJS Tic-Tac-Toe",
      description: "Tic Tac Toe programmed using the AngularJS framework and TypeScript. I used component interaction, dynamic styling and animation, DOM manipulation, and event handling.",
      githubLink: "https://angularpwa-tictactoe.netlify.app/",
      imageSrc: "/TicTacToeAngular.png",
    },
    {
      title:'Projects',
      id: 3,
      name: "Car Driving Game",
      description: "Car driving game programmed in JavaScript. Use [w s a d] keys to drive the car around. Work in progress.",
      githubLink: "https://driftgame.netlify.app/",
      imageSrc: "/drift.png",
    },
    {
      title:'Projects',
      id: 4,
      name: "Java Calculator",
      description: "Basic calculator programmed using Java. This program implemented use of the Swing Library.",
      githubLink: "https://github.com/Logand50/java-calculator",
      imageSrc: "/CalculatorJava.png",
      
    },
    {
      title:'Projects',

      id: 5,
      name: "Tic-Tac-Toe Game",
      description: "Tic Tac Toe Game programmed in Python, using the Pygame library. The program implements a simple computer algorithm for the single-player mode.",
      githubLink: "https://github.com/Logand50/TicTacToe",
      imageSrc: "/1687734404199.jpg",
    },
    {
      title:'Projects',

      id: 6,
      name: "Snake Game",
      description: "Snake Game programmed in Python. This code presents an engaging rendition of the classic Snake game using the Pygame library.",
      githubLink: "https://github.com/Logand50/snake_pygame",
      imageSrc: "/Snake_game.pdf.png",
    },
    {
      title:'Projects',
      id: 7,
      name: "Fetch JavaScript Game",
      description: "Game programmed for a JavaScript course. Demonstrating DOM manipulation using the JQuery library.",
      githubLink: "https://fetchjs.netlify.app/",
      imageSrc: "/fetchGame.png",
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    arrows: true,
    prevArrow: <SamplePrevArrow className="custom-prev-arrow" />,
    nextArrow: <SampleNextArrow className="custom-next-arrow" />,
  };

  return (
    <section id="projects">
      <Slider {...settings}>
        
        {projects.map((project, index) => (
          
          <div key={project.id}>

            <ProjectCard project={project} isFirst={index===0}/>
          </div>
        ))}
      </Slider>
    </section>
  );
};


const Contact = () => (
  <section id="contact">
    <div className="contact-icons">
      <h2>Contact</h2>
      <p>Email:<a id="contact-git"href="mailto:logandye50@gmail.com">logandye50@gmail.com</a></p>
      <p>Linkedin: <a target='_blank' id='contact-linkedin' href='https://www.linkedin.com/in/logan-dye-8b2b24256/'>https://www.linkedin.com/in/logan-dye-8b2b24256/</a></p>
      <p>GitHub: <a target='_blank' id='contact-git' href='https://github.com/Logand50'>https://github.com/Logand50</a></p>
      <div className="icon-container">
        <a href="https://www.linkedin.com/in/logan-dye-8b2b24256/" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faLinkedin} />
        </a>  
        <a href="https://github.com/Logand50" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faGithub} />
        </a>
      </div>
    </div>
    

  </section>
);



const App = () => {
  useEffect(() => {
    // smooth scrolling
    const smoothScroll = (target) => {
      const element = document.querySelector(target);
      const offset =80;
      if (element) {
        window.scrollTo({
          top: element.offsetTop - offset,
          behavior: "smooth"
        });
      }
    };

    // Attach smooth scrolling event listeners to the navigation links
    const links = document.querySelectorAll("a[href^='#']");
    links.forEach(link => {
      link.addEventListener("click", (e) => {
        e.preventDefault();
        smoothScroll(link.getAttribute("href"));
      });
    });
  }, []);

  return (
    <Router>
      <Navbar bg="dark" variant="dark" expand="lg">
        <Container>
        <Navbar.Brand href="#home">Logan Dye</Navbar.Brand>

            <Nav className="ml-auto">
              <Nav.Link as="a" href="#about">About</Nav.Link>
              <Nav.Link as="a" href="#employment">Employment</Nav.Link>
              <Nav.Link as="a" href="#projects">Projects</Nav.Link>
              <Nav.Link as="a" href="#skills">Skills</Nav.Link>

              <Nav.Link as="a" href="#contact">Contact</Nav.Link>
            </Nav>

        </Container>
      </Navbar>

      <div id="content">
        {/* <BackGround /> */}
        <Home />
        <About />
        <Employment />
        <Projects />
        <Skills />
        <Contact />
        
        
      </div>

      <footer className="bg-dark text-white text-center py-3">
        <p>Webpage programmed using ReactJS, CSS/HTML & Bootstrap. Hosted using Google Firebase.</p>
        <p>&copy; 2023-{new Date().getFullYear()}, Logan Dye. All rights reserved.</p>
      </footer>
    </Router>
  );
};



export default App;
